import xhr from './xhr/';

/**
 * 家庭相关 API
 */
class FamilyService {
	// 获取家庭列表
	getFamilyList(data) {
		return xhr({
			method: 'post',
			url: '/homenew/list',
			params: data,
		});
	}
	// 新增家庭
	addFamily(data) {
		return xhr({
			method: 'post',
			url: '/homenew/add',
			params: data,
		});
	}
	// 获取家庭详情
	getFamilyDetail(data) {
		return xhr({
			method: 'post',
			url: '/homenew/detail',
			params: data,
		});
	}
	// 获取用户
	getAllUsers(data) {
		return xhr({
			method: 'post',
			url: '/homenew/user',
			params: data,
		});
	}
	// 删除家庭
	deleteFamily(data) {
		return xhr({
			method: 'post',
			url: '/homenew/del',
			params: data,
		});
	}
}
export default new FamilyService();
